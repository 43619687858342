import { Badge, Button, FilterPanel, Icon, Input } from 'lynkco-up-core';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import dayjs from 'dayjs';

type ColabFilterProp = {
  updateFilters: (startDate: string, endDate: string) => void;
};

const toggleFilterPanelKey = 'e';
const modifierKeySymbol = navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl';
const toggleFilterPanelShortcutSymbol = modifierKeySymbol + '+' + toggleFilterPanelKey.toUpperCase();

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

function ColabFilters({ updateFilters }: ColabFilterProp) {
  const [startingDate, setStartingDate] = useState('');
  const [endingDate, setEndlingDate] = useState('');

  function getFilterToggleButton(): HTMLElement | null {
    return document.querySelector('.lynkco-filter-panel-filters-controls-expand');
  }

  function checkIfFilterPanelIsOpen() {
    return document.querySelector('.lynkco-filter-panel-filters-content-container') !== null;
  }

  function handleSetFilters() {
    const modifiedFormatStartDate = dayjs(startingDate).startOf('day').format(dateFormat);
    const modifiedFormatEndDate = dayjs(endingDate).endOf('day').format(dateFormat);
    updateFilters(modifiedFormatStartDate, modifiedFormatEndDate);
  }

  function handleToggleFilterPanel(flag: 'open' | 'close') {
    const filterToggleButton = getFilterToggleButton();
    const isFilterPanelOpen = checkIfFilterPanelIsOpen();

    if (!filterToggleButton) {
      return;
    }

    switch (flag) {
      case 'open':
        if (!isFilterPanelOpen) {
          filterToggleButton.click();
        }
        break;
      case 'close':
        if (isFilterPanelOpen) {
          filterToggleButton.click();
        }
        break;
      default:
        break;
    }
  }

  useHotkeys(toggleFilterPanelShortcutSymbol, () => {
    const isFilterPanelOpen = checkIfFilterPanelIsOpen();
    const flag = isFilterPanelOpen ? 'close' : 'open';
    handleToggleFilterPanel(flag);
  });

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { value, id } = event.currentTarget;
    id === 'startDate' ? setStartingDate(value) : setEndlingDate(value);
  }

  function handleClearFilters() {
    const isFilterPanelOpen = checkIfFilterPanelIsOpen();
    if (isFilterPanelOpen) {
      updateFilters('', '');
      setStartingDate('');
      setEndlingDate('');
    }
  }

  return (
    <div className="border-b bookings-filters">
      <FilterPanel badgeText={toggleFilterPanelShortcutSymbol} onClearFilters={handleClearFilters}>
        <div className="py-3 pr-3 p-7">
          <div className="flex max-w-5xl">
            <div className="basis-1/4 pr-6">
              <h3 className="font-medium text-base">From & To</h3>
              <div className="mt-4">
                <Input
                  type="date"
                  id="startDate"
                  value={startingDate}
                  onChange={handleChange}
                  extraClasses="mb-3"
                  label="Start Date"
                />
                <Input type="date" id="endDate" label="End Date" value={endingDate} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="mt-8 w-1/4 flex">
            <Button onClick={() => handleSetFilters()}>
              Apply
              <span className="ml-2 flex items-center rounded">
                <Icon name="search" size="small" variant="light" padding={0} />
              </span>
            </Button>
            <Button onClick={() => handleToggleFilterPanel('close')} variant="white" extraClasses="ml-2">
              Close
              <span className="ml-2">
                <Badge text={toggleFilterPanelShortcutSymbol} color="gray" />
              </span>
            </Button>
          </div>
        </div>
      </FilterPanel>
    </div>
  );
}

export default ColabFilters;
